import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import Type from '../components/Type'
import Feature from '../components/Feature'
import Button from '../components/Button'
import { injectIntl, defineMessages } from 'gatsby-plugin-intl'
import {
  Unsubscribe,
  Rollup,
  Folder,
  Categories,
  HappyInbox,
} from '../components/Images'

export const messageDescriptors = defineMessages({
  title: {
    id: 'pages.features.title',
    defaultMessage: 'Features',
  },
  heading: {
    id: 'pages.features.heading',
    defaultMessage: 'Download Unroll.Me for an inbox that you’ll love',
  },
  subHeading: {
    id: 'pages.features.subHeading',
    defaultMessage: 'Email management has never been easier.',
  },
  getStarted: {
    id: 'pages.features.getStarted',
    defaultMessage: 'Get started today',
  },
  section1Title: {
    id: 'pages.features.section1Title',
    defaultMessage: 'Block emails, instantly',
  },
  section1Content: {
    id: 'pages.features.section1Content',
    defaultMessage: 'Let’s use a handy metaphor. If we equate using Unroll.Me to spring cleaning, then blocking would be akin to throwing out all the junk you’ve accumulated over time. Feels nice, doesn’t it? You now have a closet/inbox full of items that you want to keep.',
  },
  section2Title: {
    id: 'pages.features.section2Title',
    defaultMessage: 'Organize your inbox, efficiently',
  },
  section2Content1: {
    id: 'pages.features.section2Content1',
    defaultMessage: `Now that you’ve decluttered your inbox, it’s time to get
              organized. Enter the Rollup, the organizational tool you never
              knew you needed, but can no longer live without. The Rollup is
              basically a newsletter that you can curate for yourself. Shopping
              deals? Travel deals? Lifestyle blogs? News updates? Social media
              notifications?`,
  },
  section2Content2: {
    id: 'pages.features.section2Content2',
    defaultMessage: 'Whatever newsletters you want to keep — group them together and add them to a digest that will be sent to you once a day, at the time of your choosing.',
  },
  whyUsersLoveUnrollMe: {
    id: 'pages.features.whyUsersLoveUnrollMe',
    defaultMessage: 'Why users love Unroll.Me:',
  },
  section3Title: {
    id: 'pages.features.section3Title',
    defaultMessage: 'Easy on the eyes',
  },
  section3Content: {
    id: 'pages.features.section3Content',
    defaultMessage: 'See your favorite newsletters in a new, elegant way. Unroll.Me is available on every device.',
  },
  section4Title: {
    id: 'pages.features.section4Title',
    defaultMessage: 'Intuitive categories',
  },
  section4Content: {
    id: 'pages.features.section4Content',
    defaultMessage: 'Unroll.Me automatically categorizes your subscriptions in the Rollup, so you can quickly find what you want.',
  },
  section5Title: {
    id: 'pages.features.section5Title',
    defaultMessage: 'A folder with our name on it',
  },
  section5Content: {
    id: 'pages.features.section5Content',
    defaultMessage: 'Want to check a subscription individually? All emails in your Rollup also live directly inside your email client, in a folder called “Unroll.Me” They’re secure and easy to access.',
  },
})

const Features = ({ intl }) => {
  const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
    messages[key] = intl.formatMessage(messageDescriptors[key])
    return messages
  }, {})
  const signUpLink =
    typeof window !== 'undefined'
      ? `/a/signup${window.location.search}`
      : '/a/signup'
  return (
    <Layout title={messages.title}>
      <Hero>
        <Section large>
          <Wrapper tc small>
            <Type variant="h1" f1_ns lh_title dark_gray mb0>
              {messages.heading}
            </Type>
            <Type variant="h2" f5 f4_ns lh_copy mb3 gray fw4>
              {messages.subHeading}
            </Type>
            <Button
              href={signUpLink}
              alt="Signup"
              primary
              soft
            >
              {messages.getStarted}
            </Button>
          </Wrapper>
        </Section>
      </Hero>

      <Section large id="features">
        <Wrapper small>
          <Feature
            title={messages.section1Title}
            image={Unsubscribe}
          >
            <Type variant="p" lh_copy gray>
              {messages.section1Content}
            </Type>
          </Feature>
        </Wrapper>
      </Section>

      <Section large id="features">
        <Wrapper small>
          <Feature title={messages.section2Title} image={Rollup} rtl>
            <Type variant="p" lh_copy gray>
              {messages.section2Content1}
            </Type>
            <Type variant="p" lh_copy gray>
              {messages.section2Content2}
            </Type>
          </Feature>
        </Wrapper>
      </Section>

      <Section mt4>
        <Wrapper tc small>
          <Type variant="h2" f3 f2_ns lh_copy>
            {messages.whyUsersLoveUnrollMe}
          </Type>
        </Wrapper>
      </Section>

      <Section large id="features">
        <Wrapper small>
          <Feature
            title={messages.section3Title}
            content={messages.section3Content}
            image={HappyInbox}
          />
        </Wrapper>
      </Section>

      <Section large id="features">
        <Wrapper small>
          <Feature
            title={messages.section4Title}
            content={messages.section4Content}
            image={Categories}
            rtl
          />
        </Wrapper>
      </Section>

      <Section large id="features">
        <Wrapper small>
          <Feature
            title={messages.section5Title}
            content={messages.section5Content}
            image={Folder}
          />
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default injectIntl(Features)
